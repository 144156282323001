import axios from "axios"

// const app_id = "18876F35-D673-4FAD-95E9-F95B64D9A46B";
// const app_token = "08fde642262e4bbeaac7be5c7ca2b1cac3d134c0";

// tmp
const app_id = "A9829476-9275-4B1E-A0BF-76A8261AB75C"
const app_token = "059dfc8fdae124a890a624c7df67edf3b87cb9da" 


const headers = {
    "Content-type": "application/json",
    "api-token": app_token,
};

export const sendFCMUserData = (action, userEmail, userId) => {

    const data = {
        action: action,
        customerId: userId,
        userEmail: userEmail
    }

    if (isIos()) {
        window.webkit.messageHandlers.FCMBridge.postMessage(data); // iOS
    } else {
        window.FCMBridge.webLogin(JSON.stringify(data)); //android 
    }
}

export const sendChatData = (action, operatorId="", userId="") => {
    const params = {
        user_ids: [userId, operatorId],
        operator_ids: [operatorId],
        is_distinct: true,
    };

    if (userId != "") {
        try {
            axios.post(`https://api-${app_id}.sendbird.com/v3/group_channels`, params, {
                headers: headers,
            }).then((res) => {
                const data = {
                    action: action,
                    customerId: userId,
                    operatorId: operatorId,
                    chatUrl: res.data["channel_url"]
                }
                alert(`sendUserchatData : action=${action}, userId=${userId}, operatorId=${operatorId}, url=${res.data["channel_url"]}`)
    
                if (isIos()) {
                    window.webkit.messageHandlers.iOSBridge.postMessage(data); // iOS
                } else {
                    window.AndroidBridge.chatData(JSON.stringify(data)); //android 
                }
            }).catch((err) => {
                if (err.response) {
                    alert(
                        `ERR : ${JSON.stringify(err.response.status)} - 
                            ${JSON.stringify(err.response.data)}`
                    );
                } else if (err.request) {
                    alert(`ERR : No response received - ${JSON.stringify(err.request)}`);
                } else {
                    alert(`ERR : ${err.message}`);
                }
            });
        } catch (error) {
            alert(`Error in axios : ${error}`);
        }
    } else {
        const data = {
            action : action,
            operatorId : operatorId
        }

        if (isIos()) {
            window.webkit.messageHandlers.iOSBridge.postMessage(data); // iOS
        } else {
            window.AndroidBridge.chatData(JSON.stringify(data)); //android 
        }
    }

}

const isIos = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}