import React, { useState, Fragment, useEffect, useRef } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import { deleteFromCart, deleteAllFromCart } from "../../../../redux/actions/cartActions";
import { setUser } from "../../../../redux/actions/userAction";
import { setLocalData, getLocalData } from '../../../../util/helper';
import constant from '../../../../util/constant';
import WebService from '../../../../util/webService';
import Swiper from "react-id-swiper";
const MyPageSummary = ({ userData, membType ,strings}) => {
	const [useDetails, setUseDetails] = useState({});
	const [uriMemTypeValue, setUriMemTypeValue] = useState('');
	const history = useHistory();

	/* ==============================
	================================= */
	const uriLocation = useLocation();
	useEffect(() => {
		const membType = uriLocation.state?.membType;
		// ** console.log( "MyPageSummary membType : ", membType );
		setUriMemTypeValue( membType || "CUSTOMER" );
		if (getLocalData('thekey') !== process.env.REACT_APP_BACKEND) {
			setLocalData('thekey', process.env.REACT_APP_BACKEND);
		}

		// getCart(cartData.code, userData)
		/*
		if ( getLocalData('thekey') === window._env_.APP_BASE_URL ) {
			setLocalData('thekey', window._env_.APP_BASE_URL)
		} else {
			setLocalData('thekey', window._env_.APP_BASE_URL)
		}
		// */
		// let startTime = new Date(getLocalData('session'));
		// let endTime = new Date();
		// var diffMs = (endTime - startTime);
		// var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
		// if (diffMins > 30) {
		//   logout()
		// }
		if ( userData ) {
			getProfile();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ userData, history ]);		// ** getProfile, userData
	const getProfile = async () => {
		let action = constant.ACTION.AUTH + constant.ACTION.CUSTOMER + constant.ACTION.PROFILE;
		try {
			let response = await WebService.get(action);
			if ( response ) {
				setUseDetails( response )
			}
		} catch (error) {
			setUser('')
			setLocalData('token', '')
			history.push('/')
		}
	}

	/* ==============================
	*
	================================= */
	const swiperRef = useRef(null);
	const swiperParamsPagination = [ strings["Purchase management"], strings["Sales management"] ];
	const [isSwiperPlaying, setIsSwiperPlaying] = useState(true);
	const swiperParams = {
		spaceBetween : 0
		, slidesPerView : 1
		, loopedSlides : 1
		, touchRatio : 0.8
		, freeMode : false
		, loop : true
		, slideToClickedSlide : true
		, effect : "card"
		, speed : 2000
		, autoplay : {
			delay : 10000
			, disableOnInteraction : false
		}
		, navigation: {
			nextEl : ".swiper-button-next"
			, prevEl : ".swiper-button-prev"
		}
		, renderPrevButton : () => (
			<button className="swiper-button-prev">
				<span className="blind">{strings["previous"]}</span>
			</button>
		)
		, renderNextButton : () => (
			<button className="swiper-button-next">
				<span className="blind">{strings["next"]}</span>
			</button>
		)
		, pagination : {
			el : "#MyPageSummaryPagination"
			, clickable : true
			, renderBullet : function ( index, className ) {
				return `
					<li class="${className}">
						<button type="button">
							<span>${swiperParamsPagination[index]}</span>
						</button>
					</li>
				`;
			}
		}
	};
	const stopSwiper = () => {
		if ( swiperRef.current && swiperRef.current.swiper ) {
			swiperRef.current.swiper.autoplay.stop();
			setIsSwiperPlaying(false);
		}
	};
	const startSwiper = () => {
		if ( swiperRef.current && swiperRef.current.swiper ) {
			swiperRef.current.swiper.autoplay.start();
			setIsSwiperPlaying(true);
		}
	};
	return (
		<Fragment>
			{
				uriMemTypeValue === "CUSTOMER" &&
				<Fragment>
					<div className={uriMemTypeValue}>
						<div className="mName">
							<div className="pTit1">
								<a href={`/my-store/${useDetails.storeCode}`} target="_blank" title="새창열기" className="myStoreLink">
									<em>{useDetails.firstName} {strings["sir"]}</em>
								</a>
								<span>{strings["MY STORE OF"]}</span>
							</div>
							<div className="pStar1">
								<span className="pTxt1">0</span>
								<i className="ri-star-fill pIco1"></i>
							</div>
						</div>
						<div className="mCoupon d-none">
							<h3 className="pTit2">{strings["coupon"]}</h3>
							<span className="pNum1">
								<span className="num">1</span><span className="unit">{strings["Sheet"]}</span>
							</span>
							<div className="pUtil1">
								<dl className="flexWrap">
									<dt className="flexL"></dt>{strings["A downloadable coupon"]}
									<dd className="flexR"><em>0</em>{strings["Sheet"]}</dd>
								</dl>
								<dl className="flexWrap">
									<dt className="flexL">{strings["Coupons due to expire within 7 days"]}</dt>
									<dd className="flexR"><em>1</em>{strings["Sheet"]}</dd>
								</dl>
							</div>
						</div>
						<div className="mCash d-none">
							<h3 className="pTit2">{strings["KBiz Cash"]}</h3>
							<span className="pNum1">
								<span className="num">2,197</span><span className="unit">{strings["won"]}</span>
							</span>
							<div className="pUtil1">
								<div className="flexWrap">
									<span className="flexL">{strings["Charging the KBiz Cash"]}</span>
									<span className="flexR">
										<button type="button" className="cp-nBtn2">{strings["Cash charging"]}</button>
									</span>
								</div>
							</div>
						</div>
						<div className="mGq d-none">
							<h3 className="pTit2">{strings["GQ ticket"]}</h3>
							<span className="pNum1">
								<span className="num">5</span><span className="unit">{strings["Pcs"]}</span>
							</span>
							<div className="pUtil1">
								<div className="flexWrap">
									<span className="flexL">
										<span>{strings["When your run out of GQ tickets"]}</span>
										<span>{strings["It can be purchased and used"]}</span>
									</span>
									<span className="flexR">
										<button type="button" className="cg-nBtn2">{strings["Shortcut"]}</button>
									</span>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			}
			{/* deploy test */}
			{
				uriMemTypeValue === "ENTERPRISE" &&
				<Fragment>
					<div className={uriMemTypeValue}>
						<div className="mName">
							<div className="pTit1">
								<a href={`/my-store/${useDetails.storeCode}`} target="_blank" title={strings["Open in New Window"]} className="myStoreLink">
									<em>{useDetails.firstName} {strings["sir"]}</em>
								</a>
								<span>{strings["MY STORE OF"]}</span>
							</div>
							<div className="pStar1">
								<span className="pTxt1">0</span>
								<i className="ri-star-fill pIco1"></i>
							</div>
						</div>
						<Swiper {...swiperParams} ref={swiperRef} >
							<div className="swiper-slide d-none">
								<div className="mCoupon">
									<h3 className="pTit2">{strings["coupon"]}</h3>
									<span className="pNum1">
										<span className="num">1</span><span className="unit">{strings["Sheet"]}</span>
									</span>
									<div className="pUtil1">
										<dl className="flexWrap">
											<dt className="flexL">{strings["A downloadable coupon"]}</dt>
											<dd className="flexR"><em>0</em>{strings["Sheet"]}</dd>
										</dl>
										<dl className="flexWrap">
											<dt className="flexL">{strings["Coupons due to expire within 7 days"]}</dt>
											<dd className="flexR"><em>1</em>{strings["Sheet"]}</dd>
										</dl>
									</div>
								</div>
								<div className="mCash">
									<h3 className="pTit2">{strings["Charging the KBiz Cash"]}</h3>
									<span className="pNum1">
										<span className="num">2,197</span><span className="unit">{strings["won"]}</span>
									</span>
									<div className="pUtil1">
										<div className="flexWrap">
											<span className="flexL">{strings["Charging the KBiz Cash"]}</span>
											<span className="flexR">
												<button type="button" className="cp-nBtn2">{strings["Cash charging"]}</button>
											</span>
										</div>
									</div>
								</div>
								<div className="mGq">
									<h3 className="pTit2">{strings["GQ ticket"]}</h3>
									<span className="pNum1">
										<span className="num">5</span><span className="unit">{strings["Quantity"]}</span>
									</span>
									<div className="pUtil1">
										<div className="flexWrap">
											<span className="flexL">
												<span>{strings["When your run out of GQ tickets"]}</span>
												<span>{strings["It can be purchased and used"]}</span>
											</span>
											<span className="flexR">
												<button type="button" className="cg-nBtn2">{strings["Shortcut"]}</button>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide d-none">
								<div className="mOrder">
									<div className="mInner">
										<h3 className="pTit2">{strings["A new order"]}</h3>
										<Link to="" className="pNum1">
											<span className="num">12</span><span className="unit">{strings["Pcs"]}</span>
										</Link>
									</div>
								</div>
								<div className="mGQ">
									<div className="mInner">
										<h3 className="pTit2">{strings["Order GQ"]}</h3>
										<Link to="" className="pNum1">
											<span className="num">3</span><span className="unit"></span>
										</Link>
									</div>
								</div>
								<div className="mRepl">
									<div className="mInner">
										<h3 className="pTit2">{strings["An unanswered inquiry"]}</h3>
										<Link to="" className="pNum1">
											<span className="num">2</span><span className="unit">{strings["Pcs"]}</span>
										</Link>
									</div>
								</div>
							</div>
						</Swiper>
						<div className="swiper-pagination-wrap">
							<ul id="MyPageSummaryPagination" className="swiper-pagination"></ul>
						</div>
						<div className="swiper-auto-controller">
							<button type="button" className={`stop ${isSwiperPlaying ? "on":""}`} onClick={stopSwiper}>
								<span className="blind">{strings["stop"]}</span>
							</button>
							<button type="button" className={`play ${!isSwiperPlaying ? "on":""}`} onClick={startSwiper}>
								<span className="blind">{strings["play"]}</span>
							</button>
						</div>
					</div>
				</Fragment>
			}
		</Fragment>
	);
};
const mapStateToProps = state => {
	return {
		currentLanguageCode: state.multilanguage.currentLanguageCode,
		defaultStore: state.merchantData.defaultStore,
		isLoading: state.loading.isLoading,
		userData: state.userData.userData
	}
}
const mapDispatchToProps = dispatch => {
	return {
		deleteFromCart: (cartId, item, defaultStore, addToast) => {
			dispatch(deleteFromCart(cartId, item, defaultStore, addToast));
		},
		setUser: (data) => {
			dispatch(setUser(data));
		},
		deleteAllFromCart: () => {
			dispatch(deleteAllFromCart())
		}
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(MyPageSummary));