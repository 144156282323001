import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { isMobile } from "react-device-detect";

const isLocalhost = window.location.protocol === "https:" || window.location.hostname === "localhost"

const vapidKey = process.env.REACT_APP_FB_VAPID_KEY
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_APIKEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

export const requestPermission = async () => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const messaging = getMessaging();

    const permission = await Notification.requestPermission();
    const currentUrl = window.location.pathname;
    if (currentUrl === "/" && permission === "denied") {

        alert("알림 권한이 없으면 채팅, 상품의 안내를 받을 수 없습니다.")
        return;
    }

    const token = await getToken(messaging, {
        vapidKey: vapidKey,
    }).then((currentToken) => {
        if (currentToken) {
            // console.log(currentToken)
        } else {
            // console.log("err")    
        }
    }).catch((err) => {
        console.log(err)
    })

    onMessage(messaging, (payload) => {
        // console.log("메시지가 도착했습니다.", payload);

        new Notification(payload.notification.title, {
            body: payload.notification.body
        })
    });
}

if (isLocalhost && !isMobile) {
    requestPermission()
}
