import React from "react";
import MyPageSummary from "../pages/other/MyPage/frame/MyPageSummary";
import MyPageMenu from "../pages/other/MyPage/frame/MyPageMenu";
import WithAuth from "../components/WithAuth";
import { useLocation } from "react-router-dom";


const MyPageLayout = ({ children }) => {
  const {pathname} = useLocation()
  return (
    <div className="myArea">
      <div className="myPkg">
        <div className="mySumm">
          <MyPageSummary />
        </div>
        <div className="myMenu">
          <MyPageMenu  pathname={pathname}/>
        </div>
        <div className="myContents">{children}</div>
      </div>
    </div>
  );
};

export default WithAuth(MyPageLayout);
