import React, { Fragment } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
const LayoutLink1Config = ({ items = [] }) => {
  return (
    <div className="poss">
      <ul>
        {items.map((item, index) => (
          <Fragment key={index}>
            {item.link.startsWith("http") ? (
              <li>
                <a
                  href={item.link}
                  target="_blank"
                  title="새창열기"
                  className="boxPkg"
                  rel="noopener noreferrer"
                >
                  <div className="boxInner">
                    <i className={`${item.ico} ico`}></i>
                    <span className="txt">{item.txt}</span>
                  </div>
                </a>
              </li>
            ) : (
              <li>
                <Link to={item.link} className="boxPkg">
                  <div className="boxInner">
                    <i className={`${item.ico} ico`}></i>
                    <span className="txt">{item.txt}</span>
                  </div>
                </Link>
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLanguageCode: state.multilanguage.currentLanguageCode,
    defaultStore: state.merchantData.defaultStore,
    isLoading: state.loading.isLoading,
  };
};
export default connect(mapStateToProps)(multilanguage(LayoutLink1Config));
