import React, { createContext, useContext, useState, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { multilanguage } from "redux-multilanguage";
const AlertContext = createContext();
export const useAlert = () => {
	return useContext(AlertContext);
};
const AlertProvider = ({ children, strings }) => {
	const [alert, setAlert] = useState({
		visible: false,
		type: "alert", // 'alert' or 'confirm'
		title: "",
		message: "",
		onConfirm: null,
		cancel: "닫기",
		confirm: "확인",
		confirmStyle:""
	});
	const showAlert = useCallback((options) => {
		setAlert(prevAlert => ({
			...prevAlert,
			...options,
			visible: true,
		}));
	}, []);
	const showConfirm = useCallback((options) => {
		setAlert(prevAlert => ({
			...prevAlert,
			...options,
			type: 'confirm',
			visible: true,
		}));
	}, []);
	const closeAlert = useCallback(() => {
		setAlert(prevAlert => ({
			...prevAlert,
			visible: false,
		}));
	}, []);
	const handleConfirm = useCallback(() => {
		if (alert.onConfirm) {
			alert.onConfirm();
		}
		closeAlert();
	}, [alert, closeAlert]);
	return (
		<AlertContext.Provider value={{ showAlert, showConfirm }}>
			{children}
			{alert.visible && (alert.type === "alert" ? (
				<SweetAlert title={alert.title} onConfirm={closeAlert} confirmBtnText={alert.confirm}>
					{alert.message}
				</SweetAlert>
			) : (
				<SweetAlert title={alert.title} onConfirm={handleConfirm} cancelBtnText={alert.cancel} confirmBtnText={alert.confirm} confirmBtnBsStyle={alert.confirmStyle} onCancel={closeAlert} showCancel >
					{alert.message}
				</SweetAlert>
			))}
		</AlertContext.Provider>
	);
};
export default multilanguage(AlertProvider)