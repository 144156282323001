import WebService from "../../util/webService";
import constant from "../../util/constant";

import { setLoader } from "../actions/loaderActions";
import { setLocalData, getLocalData } from "../../util/helper";
import Cookies from "universal-cookie";
export const GET_SHOPIZER_CART_ID = "GET_SHOPIZER_CART_ID";
export const GET_CART = "GET_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const UPDATE_CART_DATA = 'UPDATE_CART_DATA';

//add to cart
export const addToCart = (
  item,
  addToast,
  cartId,
  quantityCount,
  defaultStore,
  userData,
  actionType
) => {
  return async (dispatch) => {
    dispatch(setLoader(true));

    try {
      let action;
      let param;
      let response;
      let message;
      const selectedProductOptions = undefined;
      param = {
        sku: item,
        quantity: quantityCount,
        shippingType: "NATIONAL",
        internationalTransportationMethod: "TRUCK",
        nationalTransportationMethod: "TRUCK",
        shippingTransportationType: "DIRECT_DELIVERY",
      };
      // ** console.log('Item ' + item.sku + " quantity " + quantityCount);
      // if (selectedProductOptions !== undefined) {
      //   // param = { "attributes": selectedProductOptions, "product": item.sku, "quantity": quantityCount }
      //   param = {
      //     attributes: selectedProductOptions,
      //     sku: item.sku,
      //     quantity: quantityCount,
      //   };
      // } else {
      //   // param = { "product": item.sku, "quantity": quantityCount }
      //   param = {
      //     sku: item.sku,
      //     quantity: quantityCount,
      //   };
      // }
      // ** console.log('Cart parameters ' + JSON.stringify(param));
      if (actionType === "Updated") {
        message = "장바구니 수정완료";
        action = constant.ACTION.AUTH + constant.ACTION.CART;
        // + '?store=' + window._env_.APP_MERCHANT;
        response = await WebService.put(action, param);
        dispatch(getCart(userData));
      } else {
        message = "장바구니 추가완료";
        action = constant.ACTION.AUTH + constant.ACTION.CART;
        response = await WebService.post(action, param);
        dispatch(getCart(userData));
      }
      if (response) {
        dispatch(setShopizerCartID(response.code));
        dispatch(setLoader(false));
        dispatch(getCart(userData));

        addToast(message, {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch (error) {
      dispatch(setLoader(false));
      addToast("Failed to add", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
};

//Get cart

export const getCart = (userData) => {
  return async (dispatch) => {
    try {
      let action;
      if (userData) {
        // action = constant.ACTION.AUTH + constant.ACTION.CART +"?lang=ko";
        // + cartID + '&lang=' + JSON.parse(getLocalData('redux_localstorage_simple')).multilanguage.currentLanguageCode;

        action =
          constant.ACTION.AUTH +
          constant.ACTION.CART +
          "?lang=" +
          JSON.parse(getLocalData("redux_localstorage_simple")).multilanguage
            .currentLanguageCode;
      } else {
        action =
          constant.ACTION.AUTH +
          constant.ACTION.CART +
          "?lang=" +
          JSON.parse(getLocalData("redux_localstorage_simple")).multilanguage
            .currentLanguageCode;
      }

      let response = await WebService.get(action);
      if (response) {
        dispatch(setShopizerCartID(response.code));
        dispatch({
          type: GET_CART,
          payload: response,
        });
      }
    } catch (error) {
      console.log("Cart action response " + error);
      dispatch(setLoader(false));
    }
  };
};
export const getCartCode = (code) => {
  return async (dispatch) => {
    try {
      let action;
      if (code) {
        // action = constant.ACTION.AUTH + constant.ACTION.CART +"?lang=ko";
        // + cartID + '&lang=' + JSON.parse(getLocalData('redux_localstorage_simple')).multilanguage.currentLanguageCode;

        action = constant.ACTION.AUTH + constant.ACTION.CART + `?code=${code}`;
      }
      let response = await WebService.get(action);
      if (response) {
        dispatch(setShopizerCartID(response.code));
        dispatch({
          type: GET_CART,
          payload: response,
        });
      }
    } catch (error) {
      console.log("Cart action response " + error);
      dispatch(setLoader(false));
    }
  };
};
export const setShopizerCartID = (id) => {
  //set local data
  // set cart id in cookie
  var cart_cookie = window._env_.APP_MERCHANT + "_shopizer_cart";
  const cookies = new Cookies();
  cookies.set(cart_cookie, id, { path: "/", maxAge: 20000000 }); //6 months
  setLocalData(GET_SHOPIZER_CART_ID, id);
  return (dispatch) => {
    dispatch({
      type: GET_SHOPIZER_CART_ID,
      payload: id,
    });
  };
};

export const getShopizerCartID = () => {
  //set local data
  // set cart id in cookie
  var cart_cookie = window._env_.APP_MERCHANT + "_shopizer_cart";
  const cookies = new Cookies();
  let cookie = cookies.get(cart_cookie);
  if (cookie) {
    getCart(cookie, null);
  }
};

//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  console.log("decrease " + JSON.stringify(item));
  // return dispatch => {
  //   // if (addToast) {
  //   //   addToast("Item Decremented From Cart", {
  //   //     appearance: "warning",
  //   //     autoDismiss: true
  //   //   });
  //   // }
  //   // dispatch({ type: DECREASE_QUANTITY, payload: item });

  // };
};

export const increaseQuantity = (item, addToast) => {
  console.log("increase " + JSON.stringify(item));
  return (dispatch) => {
    // if (addToast) {
    //   addToast("Item Decremented From Cart", {
    //     appearance: "warning",
    //     autoDismiss: true
    //   });
    // }
    // dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};

//delete from cart
export const deleteFromCart = (
  cartID,
  item,
  defaultStore,
  userData,
  addToast
) => {


  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      let action = `auth/customer_cart/product/${item.sku}`;
      let response = await WebService.delete(action);
      dispatch(getCart(userData));
      if (response) {
        if (addToast) {
          addToast("Removed From Cart", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
      dispatch({
        type: DELETE_FROM_CART,
        payload: item.sku,
      });

      dispatch(setLoader(false));
    } catch (error) {
      console.log("Error removing from cart " + cartID);
      dispatch(setLoader(false));
    }
  };
};
//delete all from cart
export const deleteMultiCart = (addToast, items, userData) => {
  return async (dispatch) => {
    dispatch(setLoader(false));
    try {
      let action = constant.ACTION.AUTH + constant.ACTION.CART + "del_multi";
      let param = items
        .filter((item) => item.checked)
        .map((item) => ({ sku: item.id }));
      let response = await WebService.post(action, param);
      dispatch(getCart(userData));
      dispatch(setLoader(false));
      if (response) {
        if (addToast) {
          addToast("Removed From Cart", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    } catch (error) {
      console.log("Error removing from cart ");
      dispatch(setLoader(false));
    }

    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    // orderID 부분 에러, 임시 주석 기능에 문제 없음
    // dispatch({ type: DELETE_ALL_FROM_CART, payload: orderID });
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};
export const deleteAllFromCart = (addToast, items, userData) => {
  const { cartItems } = items;

  return async (dispatch) => {
    dispatch(setLoader(false));
    try {
      let action = constant.ACTION.AUTH + constant.ACTION.CART + "del_multi";
      let param = cartItems.map((item) => ({ sku: item.sku }));
      let response = await WebService.post(action, param);
      dispatch(getCart(userData));
      dispatch(setLoader(false));
      if (response) {
        if (addToast) {
          addToast("Removed From Cart", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    } catch (error) {
      console.log("Error removing from cart ");
      dispatch(setLoader(false));
    }

    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
    // orderID 부분 에러, 임시 주석 기능에 문제 없음
    // dispatch({ type: DELETE_ALL_FROM_CART, payload: orderID });
  };
};
// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock;
  }
};


export const updateCartData = (cartItems) => ({
  type: UPDATE_CART_DATA,
  payload: cartItems,
});