
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import storeReducer from "./storeReducer";
import loaderReducer from "./loaderReducer";
import userReducer from "./userReducer";
import contentReducer from "./contentReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import favoriteReducer from "./favoriteReducer";
import cacheIdReducer from "./cacheIdReducer";
import { RESET_STORE } from "../actions/storeAction";
import currencyReducer from "./currencyReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "ko" }),
  // currencyData: currencyReducer,
  productData: productReducer,
  merchantData: storeReducer,
  cartData: cartReducer,
  favoriteData:favoriteReducer,
  loading: loaderReducer,
  userData: userReducer,
  content: contentReducer,
  cacheId: cacheIdReducer,
  currencyData: currencyReducer, // 수정
});


export default rootReducer;

export const resetReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    // userData를 undefined로 설정하여 제외
    return {
      ...rootReducer(undefined, {}),
      userData: state?.userData // 기존 userData 유지
    };
  }

  return rootReducer(state, action);
};