import { getMessaging, deleteToken } from "firebase/messaging";
import Constant from "./constant";
import WebService from "./webService";
import { isMobile } from "react-device-detect";
import { requestPermission } from "../firebase-messaging-sw";
import { sendFCMUserData } from "./MobileBridge"; 

const isLocalhost = window.location.protocol === "https:" || window.location.hostname === "localhost"

export const DeleteFCMToken = async () => {
    const FCMToken = await getFCMToken()
    if (isLocalhost && !isMobile && FCMToken) {
        DeleteFCMTokenInExtenalAPI(FCMToken)
        try {
            const messaging = getMessaging();
            await deleteToken(messaging);

        } catch (error) {

        }
    } else if (isMobile) {
        sendFCMUserData("LOGOUT", "", "")
    }
}

export const PostFCMToken = async (userEmail, userId) => {
    const FCMToken = await getFCMToken()
    if (isLocalhost && !isMobile && FCMToken) {
        postFCMTokenInExternalAPI(userEmail, userId, FCMToken)
    } else if (isMobile) {
        sendFCMUserData("LOGIN", userEmail, userId)
    } else if (isLocalhost) {
        const request = requestPermission()
    }
}

export const getFCMToken = async () => {
    if (isLocalhost && !isMobile) {
     
        try {
            const dbOpenRequest = indexedDB.open('firebase-messaging-database', 1);
    
            return new Promise((resolve, reject) => {
                dbOpenRequest.onsuccess = (event) => {
                    const db = event.target.result;
                    const transaction = db.transaction(['firebase-messaging-store'], 'readonly');
                    const objectStore = transaction.objectStore('firebase-messaging-store');
                    const tokenRequest = objectStore.getAll();
    
                    tokenRequest.onsuccess = (event) => {
                        const allTokens = event.target.result;
                        if (allTokens.length > 0) {
                            const fcmToken = allTokens[0].token;
                            resolve(fcmToken);
                        } else {
                         
                            resolve(null);
                        }
                    };
    
                    tokenRequest.onerror = () => {
                      
                        reject(new Error('Error accessing token-details in IndexedDB'));
                    };
                };
    
                dbOpenRequest.onerror = () => {
                 
                    reject(new Error('Error opening IndexedDB'));
                };
            });
        } catch (error) {
            return null;
        }
    }
}

const postFCMTokenInExternalAPI = async (userEmail, userId, FCMToken) => {
    let action = Constant.ACTION.FIREBASE + Constant.ACTION.TOKEN
   
    const params = {
        id: null,
        token: FCMToken,
        customerId: userId,
        email: userEmail,
        osType: "WEB"
    }

    try {
        let response = await WebService.EXT.post(action, params)

        if(response) {
            // console.log(`insert token response : ${response}`)
        }
    } catch (error) {
        // console.log(error)
    }
}

const DeleteFCMTokenInExtenalAPI = async (FCMToken) => {
    let action = Constant.ACTION.FIREBASE + Constant.ACTION.TOKEN + "?token=" + FCMToken
    // console.log(`deleteFCMToken ${FCMToken}`)
    let response = await WebService.EXT.delete(action)
    if (response) {
        // console.log(`deletetoken is work ${response}`)
    } else {
        // console.log(`deletetoken is no worked!`)
    }
}

