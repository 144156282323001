import WebService from "../../util/webService";
import constant from "../../util/constant";
import { setLoader } from "./loaderActions";
import { getLocalData } from "../../util/helper";

export const GET_FAVORITE = "GET_FAVORITE";
export const ADD_TO_FAVORITE = "ADD_TO_FAVORITE";
export const DELETE_FROM_FAVORITE = "DELETE_FROM_FAVORITE";

//ADD_TO_FAVORITE
export const addToFavorite = (userData, productId) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    let param = { productId: productId, userId: userData.id };
    // if(isBookMark===false){
    let action = constant.ACTION.AUTH + constant.ACTION.FAVORITE + constant.ACTION.PRODUCT; // ** /auth/favorite/product
    try {
      await WebService.post(action, param);

      dispatch(getFavorite(userData));
      dispatch(setLoader(false));
    } catch (error) {
      console.log("핸들오류:", error);
      dispatch(setLoader(false));
    }
  };
};

//DELETE_FROM_FAVORITE
export const deleteFromFavorite = (userData, productId) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    let action =
      constant.ACTION.AUTH + constant.ACTION.FAVORITE + constant.ACTION.PRODUCT + `${productId}?userId=${userData.id}`; // ** /auth/favorite/product
    try {
      await WebService.delete(action);

      dispatch({
        type: DELETE_FROM_FAVORITE,
        payload: productId,
      });
      dispatch(getFavorite(userData));

      dispatch(setLoader(false));

    } catch (error) {
      console.log("핸들오류:", error);
      dispatch(setLoader(false));
    }
  };
};

//GET_FAVORITE
export const getFavorite = (userData) => {
  return async (dispatch) => {
    let action =
      constant.ACTION.AUTH +
      constant.ACTION.FAVORITE +
      "list" +
      `?userId=${userData.id}` +
      "&lang=" +
      JSON.parse(getLocalData("redux_localstorage_simple")).multilanguage.currentLanguageCode; // ** /auth/customer/profile
    try {
      let response = await WebService.get(action);
      if (response) {
        dispatch({
          type: GET_FAVORITE,
          payload: response,
        });
       
      }
    } catch (error) {
      console.log("찜리스트 오류:", error);
      dispatch(setLoader(false));
    } finally {
      dispatch(setLoader(false));
    }
  };
};

export const getIsFavorite = (productId) => {
  return async (dispatch) => {
    let action =
      constant.ACTION.AUTH +
      constant.ACTION.FAVORITE +
      "user" +
      "/is_favorited" +
      `/${productId}` 
    try {
      let response = await WebService.get(action);
      if (response) {
      return response.data;
      }
    } catch (error) {
      console.log("찜리스트 오류:", error);
      dispatch(setLoader(false));
    } finally {
      dispatch(setLoader(false));
    }
  };
};
