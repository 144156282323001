import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom"; // ** useRouteMatch
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { setUser } from "../../redux/actions/userAction";
import { getCart } from "../../redux/actions/cartActions";
import { setLocalData, getLocalData } from "../../util/helper";
import { multilanguage } from "redux-multilanguage";
import { changeLanguage } from "redux-multilanguage";
// ** import { GoogleTranslateEntire } from '../../util/utilFnc';
import IdleTimer from "react-idle-timer";
import constant from "../../util/constant";
import WebService from "../../util/webService";
import { DeleteFCMToken, PostFCMToken } from "../../util/FCMTokenManager";
// import { CurrencyExchanger } from "../../util/utilFnc";
import { resetStore, setExchangeCode, setMerchant, setStore, } from "../../redux/actions/storeAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { setCurrencySymbol } from "../../redux/actions/currencyAction";
const IconGroup = ({ cartData, cartCount, deleteFromCart, iconWhiteClass, userData, setUser, setMerchant, strings, getCart, resetStore, setExchangeCode, currentLanguageCode, exchangeData, exchangeCode, dispatch, }) => {
	/* wishlistData, compareData, currency */
	// ** const pathname = useRouteMatch();
	const history = useHistory();
	const timeout = 1000 * 60 * 30;
	const priceElementsRef = useRef([]);
	// const [idleTimer, setIdleTimer] = useState(null);
	// const [searchData, setSearchData] = useState([]);
	// const [searchText, setSearchText] = useState('');
	const [useDetails, setUseDetails] = useState({});
	const [useDetailsGroup, setUseDetailsGroup] = useState({});
	const [rateKRW, setRateKRW] = useState("");
	const [rateCNH, setRateCNH] = useState("");
	const [rateUSD, setRateUSD] = useState("");
	const [rateJPY, setRateJPY] = useState("");
	const [showMenuCart, setShowMenuCart] = useState(true);
	const location = useLocation();
	useEffect(() => {
		// const timeoutId = setTimeout(() => {
		// 	setShowMenuCart(true);
		// }, 100);
		if (getLocalData("thekey") === process.env.REACT_APP_BACKEND) {
		setLocalData("thekey", process.env.REACT_APP_BACKEND);
		} else {
		logout();
		setLocalData("thekey", process.env.REACT_APP_BACKEND);
		}
		/*
			getCart(cartData.code, userData)
			if ( getLocalData('thekey') === window._env_.APP_BASE_URL ) {
				setLocalData('thekey', window._env_.APP_BASE_URL)
			} else {
				logout()
				setLocalData('thekey', window._env_.APP_BASE_URL)
			}
			let startTime = new Date(getLocalData('session'));
			let endTime = new Date();
			var diffMs = (endTime - startTime);
			var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
			if (diffMins > 30) {
				logout()
			}
			// */
		// ** console.group( "IconGroup > useEffect" );
		// ** console.log( "useDetails : ", useDetails );
		// ** console.log( "useDetailsGroup : ", useDetailsGroup );
		// ** console.groupEnd();
		// cleanup 함수

		return () => {
		setShowMenuCart(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (userData) {
			getProfile();
			getCart(userData);
		} else {
			setUser("");
      setLocalData("token", "");
			resetStore();
		}
	}, [userData]);
	const getProfile = async () => {
		let action =
		constant.ACTION.AUTH + constant.ACTION.CUSTOMER + constant.ACTION.PROFILE; // ** /auth/customer/profile
		try {
			let response = await WebService.get(action);
			if (response) {
				// console.log(`IcomGroup: getProfile - response = ${JSON.stringify(response)}`)
				setUseDetails(response);
				setUseDetailsGroup(response.groups[0].type);
				setMerchant(response.storeCode || "DEFAULT");
				PostFCMToken(response.emailAddress, response.id)
				setLocalData("loginEmail", response.emailAddress)
			}
		} catch (error) {
			setUser("");
			setLocalData("token", "");
			history.push("/");
		}
	};
	const logout = () => {
		setUser(null);
		setLocalData("token", "");
		setLocalData("loginEmail", "")
		resetStore();
		DeleteFCMToken()
	};
	useEffect(() => {
		const activeElements = document.querySelectorAll(".active");
		activeElements.forEach((element) => {
			element.classList.remove("active");
		});
	}, [location]);

	const handleClick = async (e) => {
		let parentElement = e.currentTarget.parentElement;
		const isActive = parentElement.classList.contains("active");
		while (parentElement) {
			if (parentElement.classList.contains("header-right-wrap")) {
				Array.from(parentElement.querySelectorAll("*")).forEach((element) => {
					element.classList.remove("active");
				});
				break;
			}
			parentElement = parentElement.parentElement;
		}

		// ** Toggle
		if (!isActive) {
			e.currentTarget.parentNode.classList.add("active");
		} else {
			e.currentTarget.parentNode.classList.remove("active");
		}

		// ** 240508 : Collect tags with data-price attribute.
		const priceElements = document.querySelectorAll("[data-price]");
		priceElementsRef.current = Array.from(priceElements);

		// ** 240508 : Get national exchange rate.
		if (e.currentTarget.id === "setExchangeRateCast") {
			/* utilFnc로 이관
			try {
				const response = await fetch( "/site/program/financial/exchangeJSON?authkey=HSKEPK5RRe1ssuj43knIQVuZic62K3fV&data=AP01" );
				console.log("response : ", response);
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				const data = await response.json();

				data[13].result = 1;
				if (data[13].result === 1) {
					setRateKRW(data[13].deal_bas_r);
				} else {
					setRateKRW("");
					alert("통신이 원활하지 못해 원하시는 통화로 변경할수 없습니다.");
					return;
				}
				if (data[6].result === 1) {
					setRateCNH(data[6].deal_bas_r);
				} else {
					setRateCNH("");
					alert("통신이 원활하지 못해 원하시는 통화로 변경할수 없습니다.");
					return;
				}
				if (data[22].result === 1) {
					setRateUSD(data[22].deal_bas_r);
				} else {
					setRateUSD("");
					alert("통신이 원활하지 못해 원하시는 통화로 변경할수 없습니다.");
					return;
				}
				if (data[12].result === 1) {
					setRateJPY(data[12].deal_bas_r);
				} else {
					setRateJPY("");
					alert("통신이 원활하지 못해 원하시는 통화로 변경할수 없습니다.");
					return;
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
			// */
		}
	};
	const onAction = (e) => {
		setLocalData("session", new Date());
	};
	const onActive = (e) => {
		setLocalData("session", new Date());
	};
	const onIdle = (e) => {
		// logout()
	};

	/*
	const onSearch = async (e) => {
		setSearchText(e.target.value)
		if (e.target.value.length >= 3) {
			let action = constant.ACTION.SEARCH + constant.ACTION.AUTOCOMPLETE;
			let param = { "query": e.target.value }
			try {
				let response = await WebService.post(action, param);
				if (response) {
					setSearchData(response.values)
				}
			} catch (error) {
				console.log(error, '------------')
			}
		}
	}
	const onSelectedSearch = (data) => {
		setSearchText(data)
		setSearchData([])
	}
	const keyDownFunction = (e) => {
		if (e.keyCode === 13) {
			onSearchClick()
		}
	}
	const onSearchClick = () => {
		history.push('/search/' + searchText)
	}
	// */
	/* 240327 : No Mobile use. PC osmu
	const triggerMobileMenu = () => {
		const offcanvasMobileMenu = document.querySelector(
			"#offcanvas-mobile-menu"
		);
		offcanvasMobileMenu.classList.add("active");
	};
	// */
	const Languages = [
		{ code: "en", id: 1, unit: "USD", name: "USA" },
		{ code: "ko", id: 2, unit: "KRW", name: "KOR" },
		{ code: "zh", id: 3, unit: "CNH", name: "CHN" }, // zh : 중국어
		// {code: 'ja', id: 3,unit:"JPY(100)", name:"JPN"}
	];
	const languageConfig = {
		USA: { rate: rateUSD, symbol: "$", locale: "en", country: "USA" },
		KOR: { rate: rateKRW, symbol: "₩", locale: "ko", country: "KOR" },
		CHN: { rate: rateCNH, symbol: "¥", locale: "zh-CN", country: "CHN" },
		// JPN: { rate: rateJPY, symbol: "¥", locale: "ja" },
	};

	/* ==============================
	* 240508 : Exchange All Currency
	================================= */
	const getLanguageNameByCode = (code) => {
		const language = Languages?.find((lang) => lang.code === code);
		return language;
	};
	useEffect(() => {
		const lang = getLanguageNameByCode(currentLanguageCode);
		if (exchangeData) {
			const code = exchangeData?.find(
				(data) => data.cur_unit === lang.unit
			).deal_bas_r;
			setExchangeCode(code);
			dispatch(changeLanguage(lang.code));
		}
	}, [currentLanguageCode, exchangeData]);
	const priceChange = (lang) => {
		const config = languageConfig[lang.name];
		if (config) {
			exchangeCast(config.rate, lang.name, config.symbol, config.locale, config.country);
			dispatch(changeLanguage(lang.code));
			//   window.location.reload();
		} else {
			exchangeCast(rateJPY, lang.name, "﷼", "ar-SA" ,"ARE");
		}
		// ** 240508 : Screen UI Cleanup
		const headerLang = document.getElementById("headerLang");
		headerLang.classList.remove("active");
		headerLang.querySelector(".txt").textContent = lang.name;
	};

	const exchangeCast = (rate, currency, currencySymbol, lang, country) => {
		// ** 240508 : Insert exchange value.
		let rateNum = cleanAndParseNumber(rate);
		console.log("rate : ", rate, typeof rate);
		console.log("rateNum : ", rateNum, typeof rateNum);
		// html lang 속성 접근 변경
		document.documentElement.lang = lang;
		dispatch(setCurrencySymbol(currencySymbol, country));
		priceElementsRef.current.forEach((element) => {
			let price = element.getAttribute("data-price");
			let priceNum = cleanAndParseNumber(price);
			let convertedPrice = 0;
			if (currency === "KRW") {
				convertedPrice = (priceNum / rateNum).toFixed(0);
			} else {
				convertedPrice = (priceNum / rateNum).toFixed(2);
			}
			element.textContent = addCommasToNumber(currencySymbol + convertedPrice);
		});
	};
	const cleanAndParseNumber = (str) => {
		const cleanedStr = str.replace(/[^\d.]/g, "");
		return parseFloat(cleanedStr);
	};
	const addCommasToNumber = (num) => {
		let numStr = String(num);
		return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	return (
		<div className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`} >
			<IdleTimer element={document} onActive={onActive} onIdle={onIdle} onAction={onAction} debounce={250} timeout={timeout} />
			<ul>
				<li className="trans hasChild" id="headerLang">
					<button type="button" className="langToggle" id="setExchangeRateCast" onClick={(e) => handleClick(e)} >
						<i className="ri-global-line ico"></i>
						<span className="txt currency">
						{getLanguageNameByCode(currentLanguageCode)?.name}
						</span>
					</button>
					<div className="hideChild langSet">
						<ul>
							{Languages.map((lang) => (
								<li key={lang.code}>
									<button type="button" onClick={() => priceChange(lang)}>
										<span>{lang.name}</span>
									</button>
								</li>
							))}
							{/*
							<li>
								<button type="button" onClick={() => priceChange("SAR")}><span>SAR</span></button>
							</li>
							*/}
						</ul>
					</div>
					{/** <GoogleTranslateEntire target="#headerLang" /> */}
					{/** <CurrencyExchanger currencyWr="#headerLang" /> */}
				</li>
				{/*
					<div className="same-style header-search">
						<button className="search-active" onClick={e => handleClick(e)}>
							<i className="pe-7s-search" />
						</button>
						<div className="search-content">
							<form >
								<input type="text" placeholder={strings["Search"]} value={searchText} onKeyDown={(e) => keyDownFunction(e)} onChange={e => onSearch(e)} />
								<button className="button-search" onClick={onSearchClick}>
									<i className="pe-7s-search" />
								</button>
							</form>
							{
								searchData.length > 0 &&
								<div className="autoComplete" >
									<div className="shopping-cart-content">
										<ul>
											{
												searchData.map((value, index) => {
													return (
														<li className="single-shopping-cart" key={index} >
															<p onClick={() => onSelectedSearch(value)}>{value}</p>
														</li>
													)
												})
											}
										</ul>
									</div>
								</div>
							}
						</div>
					</div>
					<div className="same-style header-compare">
						<Link to={process.env.PUBLIC_URL + "/compare"}>
							<i className="pe-7s-shuffle" />
							<span className="count-style">
								{compareData && compareData.length ? compareData.length : 0}
							</span>
						</Link>
					</div>
					<div className="same-style header-wishlist">
						<Link to={process.env.PUBLIC_URL + "/wishlist"}>
							<i className="pe-7s-like" />
							<span className="count-style">
								{wishlistData && wishlistData.length ? wishlistData.length : 0}
							</span>
						</Link>
					</div>
				*/}
				{
				// userData && pathname.url !== '/checkout' &&
				userData && (
					<li className="cart hasChild">
						<button className="icon-cart" onClick={(e) => handleClick(e)}>
							<span className="count-style">{cartCount}</span>
							<i className="ri-shopping-cart-line ico"></i>
							<span className="txt">{strings["Cart"]}</span>
						</button>
						{/* { console.group( "IconGroup > MenuCart 직전" ) } */}
						{/* { console.log( "useDetails : ", useDetails ) } */}
						{/* { console.log( "useDetailsGroup : ", useDetailsGroup ) } */}
						{/* { console.groupEnd() } */}
						{showMenuCart && (
							<MenuCart cartData={cartData} deleteFromCart={deleteFromCart} userData={userData} membType={useDetailsGroup} /* currency={currency} */ />
						)}
					</li>
				)
				}
				{/*
					{
						pathname.url !== '/checkout' &&
						<button className="account-setting-active" onClick={e => handleClick(e)}>
							<i className="ri-user-line ico"></i>
							<span className="txt"></span>
						</button>
					}
				*/}
				{!userData && (
					<li>
						<Link to={"/login"}>
							<i className="ri-user-line ico"></i>
							<span className="txt">{strings["Login"]}</span>
						</Link>
					</li>
				)}
				{!userData && (
					<li>
						<Link to={"/register1"}>
							<i className="ri-login-box-line ico"></i>
							<span className="txt">{strings["Register"]}</span>
						</Link>
					</li>
				)}
				{userData && (
				<li className="my hasChild">
					<button type="button" onClick={(e) => handleClick(e)}>
						<i className="ri-user-line ico"></i>
						<span className="txt">{strings["My page"]}</span>
					</button>
					<div className="hideChild user-profile">
					<div className="userInfo">
						{/* { console.group( "IconGroup" ) } */}
						{/* { console.log( "userData : ", userData ) } */}
						{/* { console.log( "useDetails : ", useDetails ) } */}
						{/* { console.log( "useDetailsGroup : ", useDetailsGroup ) } */}
						{/* { console.groupEnd() } */}
						<span className="user-type">{useDetailsGroup === "ENTERPRISE" ? strings["Company"] : strings["Individual member"]}</span>
						<span className="user-name">{useDetails.firstName} {useDetails.lastName}</span>
						<span className="user-email">{useDetails.userName}</span>
					</div>
					<ul>
						<li>
							<Link to={{ pathname: "/my/account-enterprise", state: { membType: useDetailsGroup }, }} >
								<span>{strings["My Account"]}</span>
							</Link>
						</li>
						<li>
							<Link to={{ pathname: "/my/recent-order", state: { membType: useDetailsGroup }, }} >
								<span>{strings["Recent Orders"]}</span>
							</Link>
						</li>
						<li>
							<Link to={"/login"} onClick={logout}>
								<span>{strings["Logout"]}</span>
							</Link>
						</li>
					</ul>
					</div>
				</li>
				)}
				<li className="qr hasChild">
					<button type="button" onClick={(e) => handleClick(e)}>
						<i className="ri-qr-scan-2-line ico"></i>
						<span className="txt">App</span>
					</button>
					<div className="hideChild qrCode">
						<i className="ri-qr-code-fill ico"></i>
						<span className="guide">TEST</span>
					</div>
				</li>
			</ul>
			{/* 240327 : No Mobile use. PC osmu
				<div className="same-style mobile-off-canvas d-block d-lg-none">
					<button className="mobile-aside-button" onClick={() => triggerMobileMenu()}>
						<i className="pe-7s-menu" />
					</button>
				</div>
			// */}
			<div className="mobile">
				<button type="button" id="mNaviControllerBtn">
				<span className="blind txt">전체메뉴 열기</span>
				<i className="ri-menu-line ico"></i>
				</button>
			</div>
		</div>
	);
};
IconGroup.propTypes = {
	cartData: PropTypes.object.isRequired,  // 객체 타입으로 설정  ( Array error 로 인하여 PropTypes.object ->  PropTypes.object.isRequired 수정 )
	iconWhiteClass: PropTypes.string,
	deleteFromCart: PropTypes.func,
	dispatch: PropTypes.func,
	// 언어팩 관련
	currentLanguageCode: PropTypes.string,
	strings: PropTypes.object,
};
const mapStateToProps = (state) => {
	return {
		cartData: state.cartData.cartItems,
		cartCount: state.cartData.cartCount,
		userData: state.userData.userData,
		// 언어팩 관련
		merchant: state.merchantData.merchant,
		exchangeData: state.merchantData.country,
		exchangeCode: state.merchantData.code,
	};
};
const mapDispatchToProps = (dispatch) => {
return {
	deleteFromCart: (cartId, item, defaultStore, addToast) => {
		dispatch(deleteFromCart(cartId, item, defaultStore, addToast));
	},
	setUser: (data) => {
		dispatch(setUser(data));
	},
	getCart: (cartID, userData) => {
		dispatch(getCart(cartID, userData));
	},
	resetStore: () => {
		dispatch(resetStore());
	},
	setMerchant: (code) => {
		dispatch(setMerchant(code));
	},
	setCompany: (code) => {
		dispatch(setMerchant(code));
	},
	setExchangeCode: (code) => {
		dispatch(setExchangeCode(code));
	},
};
};
export default connect( mapStateToProps, mapDispatchToProps )(multilanguage(IconGroup));