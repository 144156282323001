import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import Header from "../wrappers/header/Header";
import Footer from "../wrappers/footer/Footer";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
// import { GoogleTranslateWidget } from '../util/utilFnc';
import LayoutBanner1 from "../data/Layout/LayoutBanner1.json";
import LayoutBanner1Config from "../wrappers/LayoutBanner1Config/LayoutBanner1Config";
import LayoutLink1 from "../data/Layout/LayoutLink1.json";
import LayoutLink1Config from "../wrappers/LayoutLink1Config/LayoutLink1Config";
// import LanguageCurrencyChanger from "../components/header/sub-components/LanguageCurrencyChanger";
import { MetaTags } from "react-meta-tags";
import LanguageCurrencyChanger from "../components/header/sub-components/LanguageCurrencyChanger";
const Layout = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
  currentLanguageCode,
  userData,
  dispatch,
  strings,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const isMainPage = location.pathname === "/";
  let isMainPageFlag = null;
  if (isMainPage === true) {
    isMainPageFlag = "main";
  } else {
    isMainPageFlag = "sub";
  }
  const LayoutBanner1Items = LayoutBanner1[currentLanguageCode];
  const LayoutLink1Items = LayoutLink1[currentLanguageCode];

  if (!strings) {
    return <div>2123</div>;
  }
  return (
    <Fragment>
      {/* <GoogleTranslateWidget /> */}
      <div id="wrap">
        {/* <div className="guideTestPkg">
							<div className="guideLanguageStatus">
								<LanguageCurrencyChanger strings={strings} currentLanguageCode={currentLanguageCode} dispatch={dispatch} />
							</div>
							<div className="guideBtnTest">
								<Link to="/cart">cart</Link>
								<Link to="/register1">register1</Link>
								
							</div>
							<div className="guideBackEnd">
								<span>{process.env.REACT_APP_BACKEND}</span>
							</div>
							<div className="guideLogStatus">
								{
									userData ? (
										<Fragment>
											로그인 상태
										</Fragment>
									) : (
										<Fragment>
											비로그인 상태
										</Fragment>
									)
								}
							</div>
						</div> */}

        <Header
          layout={headerContainerClass}
          top={headerTop}
          headerPaddingClass={headerPaddingClass}
          headerPositionClass={headerPositionClass}
        />
        <article id="container-wrap" className={isMainPageFlag}>
          <div id={`${isMainPageFlag}-container`}>
            <div className="container">{children}</div>
            <div className="wing sideL-banner">
              <LayoutBanner1Config items={LayoutBanner1Items} />
            </div>
            <div className="wing sideR-link">
              <LayoutLink1Config items={LayoutLink1Items} />
            </div>
          </div>
        </article>
        <Footer backgroundColorClass="" spaceTopClass="" spaceBottomClass="" />
      </div>
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
  dispatch: PropTypes.func,
  strings: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    currentLanguageCode: state.multilanguage.currentLanguageCode,
    userData: state.userData.userData,
  };
};

// export default Layout;
export default connect(mapStateToProps, null)(multilanguage(Layout));
